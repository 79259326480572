import React from "react";
import styleVars from "../../styles/style-vars";
import Icon from "../Icon";

const HeaderMenu = ({ menu, setMenu, handleButton }) => {
  return (
    <div className={`${styleVars.page.xl} header-menu ${menu ? "show" : "hide"} pt6 pb6 flex flex-column`}>
      <div className="flex justify-between align-center">
        <a href="/"><Icon name="logo" className="ma0 pa0 mh4" /></a>
        <div className="flex callus-container justify-end">
          {/* <h1 className="sig-blue ma0 f4 mw10 cp fw-bold" onClick={() => handleContact(!contact, setContact)}>CALL US</h1> */}
          <Icon name="multiply" className="ma0 pa0 mh2 ml4" onClick={() => setMenu(!menu)} />
        </div>
      </div>
      <a className="primary-text-color ma0 pa0 mt6 f5 db" href="/how-it-works">How It Works</a>
      <a className="primary-text-color ma0 pa0 mt6 f5 db" href="/merchants">Merchants</a>
      {/* <div className="flex flex-column justify-end fg1">
        <button className="btn-base btn-outlined pa3 pl6 pr6 f4 bg-blue db mt6 tc fw-bold" onClick={handleButton}>Get Early Access</button>
      </div> */}
    </div>
  );
}

export default HeaderMenu;