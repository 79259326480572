const mixpanel = require("mixpanel-browser");

const devToken = "7912d18364f60b1d125b0662d300068d";
const prodToken = "f14dd52fa677f49c91c9e625807690fe";

var token = devToken;

if (process.env.NODE_ENV === "production") {
  token = prodToken;
}

mixpanel.init(token);

export var mixpanelObj = {
  init: () => {},
  track: () => {}
};

if (typeof mixpanel === "undefined") {
  // variable is undefined or null
} else {
  mixpanelObj = mixpanel;
}

export default mixpanelObj;