import React, { useState } from "react";
import styleVars from "../../styles/style-vars";
import Icon from "../Icon";
import HeaderMenu from "./HeaderMenu";
import RequestDemo from "../product/RequestDemo";

const handleRequestDemo = (requestDemo, setRequestDemo) => {
  setRequestDemo(requestDemo);
  if (requestDemo) {
    document.documentElement.style.overflow = "hidden";
  } else {
    document.documentElement.style.overflowY = "scroll";
  }
};

const Header = ({ className }) => {
  const [menu, setMenu] = useState(false);
  const [requestDemo, setRequestDemo] = useState(false);
  return (
    <div className={`${className} header-container`} id="header-container">
      <div
        className={`${styleVars.page.xl} grid-12 gutter-row-20 gutter-36-ns pt6 pb6 align-center`}
      >
        {requestDemo ? (
          <RequestDemo
            isOpen={requestDemo}
            handleModal={() => handleRequestDemo(!requestDemo, setRequestDemo)}
          />
        ) : null}
        <HeaderMenu
          menu={menu}
          setMenu={setMenu}
          handleButton={() => handleRequestDemo(!requestDemo, setRequestDemo)}
        />
        <div className="col-6 col-2-l flex">
          <a href="/">
            <Icon name="logo" className="ma0 pa0 mh4" />
          </a>
        </div>
        <div className="col-4-l dn flex-l justify-center">
          {/* <a className="sig-blue ma0 pa0 f4 mr6 fw-bold" href="/product">HOW IT WORKS</a> */}
          {/* <a className="sig-blue ma0 pa0 f4 mr6 fw-bold" href="/about">WHO WE ARE</a> */}
          {/* <p className="sig-blue ma0 pa0 f4 cp fw-bold" onClick={() => handleContact(!contact, setContact)}>CALL US</p> */}
        </div>
        <div className="col-6-l dn flex-l ml-auto align-center">
          <a className="primary-text-color ma0 pa0 f5 mr6" href="/how-it-works">
            How It Works
          </a>
          <a className="primary-text-color ma0 pa0 f5 mr6" href="/merchants">
            Merchants
          </a>
          <button
            className="btn-base btn-outlined pa2 f6"
            style={{ minWidth: 150 }}
            onClick={() => handleRequestDemo(!requestDemo, setRequestDemo)}
          >
            Get Started
          </button>
        </div>
        <div className="col-6 flex justify-end dn-l">
          <Icon
            name="menu"
            className="ma0 pa0 mh2 ml4 db dn-l"
            onClick={() => setMenu(!menu)}
          />
        </div>
      </div>
    </div>
  );
};

export default Header;
