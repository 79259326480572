const devGAToken = "UA-144785811-1";
const prodGAToken = "UA-144785811-2";

var token = devGAToken;

console.log("aa", process.env.GATSBY_AIRTABLE_API_KEY)

if (process.env.NODE_ENV === "production") {
  token = prodGAToken;
}

export default token;
