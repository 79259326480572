import { airtableAPIKey, airtableApiUrl, airtableBase } from "../../config";

export function postRequestDemo(data) {
  let url = `${airtableApiUrl}/${airtableBase}/Requests`;
  return fetch(url, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${airtableAPIKey}`,
      "Content-Type": "application/json"
    },
    referrer: "no-referrer",
    body: JSON.stringify(data)
  }).then(response => response);
}