import React, { Component, Fragment } from "react";
import Icon from "../Icon";
import { postRequestDemo } from "./api";
import {
  InputWrapper,
  emailRegex,
  regexPhone,
  regexZip,
} from "../../lib/utils";

const USER_TYPE = Object.freeze({
  SENDER: "SENDER",
  MERCHANT: "MERCHANT",
});

class RequestDemo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      success: false,
      requestError: false,
      values: {
        businessName: "",
        contactName: "",
        contactNumber: "+1",
        email: this.props.email ? this.props.email : "",
        userType: USER_TYPE.SENDER,
        zip: "",
        message: "",
      },
      errors: {
        businessName: false,
        contactName: false,
        contactNumber: false,
        userType: false,
        email: false,
        zip: false,
      },
      errorMsg: {
        businessName: "",
        contactName: "",
        contactNumber: "",
        userType: "",
        email: "",
        zip: "",
      },
    };
  }

  handleSave = () => {
    if (this.isValid()) {
      this.setState({ loading: true });
      const { values } = this.state;
      const data = {
        records: [
          {
            fields: {
              "Business Name": values.businessName,
              "Contact Name": values.contactName,
              "Phone Number": values.contactNumber,
              "ZIP code": values.zip,
              Email: values.email,
              Message: values.message,
              "User Agent": navigator.userAgent,
            },
          },
        ],
      };
      postRequestDemo(data).then((res) => {
        if (res.status === 200) {
          this.setState({
            loading: false,
            success: true,
          });
        } else {
          this.setState({
            loading: false,
            requestError: true,
          });
        }
      });
    }
  };

  isValid = () => {
    const {
      email,
      contactName,
      contactNumber,
      zip,
      businessName,
      userType,
    } = this.state.values;
    var errors = false;
    var newState = { ...this.state };
    if (emailRegex.test(email) === false) {
      errors = true;
      newState = {
        ...newState,
        errors: { ...newState.errors, email: true },
        errorMsg: {
          ...newState.errorMsg,
          email: "Please enter a valid email",
        },
      };
    }
    if (!userType) {
      errors = true;
      newState = {
        ...newState,
        errors: { ...newState.errors, userType: true },
        errorMsg: {
          ...newState.errorMsg,
          userType: "Please fill out this field",
        },
      };
    }
    if (userType === USER_TYPE.MERCHANT && !businessName) {
      errors = true;
      newState = {
        ...newState,
        errors: { ...newState.errors, businessName: true },
        errorMsg: {
          ...newState.errorMsg,
          businessName: "Please fill out this field",
        },
      };
    }
    if (!contactNumber || regexPhone.test(contactNumber) === false) {
      errors = true;
      newState = {
        ...newState,
        errors: { ...newState.errors, contactNumber: true },
        errorMsg: {
          ...newState.errorMsg,
          contactNumber: "Please enter a valid contact number",
        },
      };
    }
    if (!contactName) {
      errors = true;
      newState = {
        ...newState,
        errors: { ...newState.errors, contactName: true },
        errorMsg: {
          ...newState.errorMsg,
          contactName: "Please fill out this field",
        },
      };
    }
    if (!zip || regexZip.test(zip) === false) {
      errors = true;
      newState = {
        ...newState,
        errors: { ...newState.errors, zip: true },
        errorMsg: {
          ...newState.errorMsg,
          zip: "Please enter a valid ZIP code",
        },
      };
    }
    this.setState(newState);
    return !errors;
  };

  handleChange = (name, value) => {
    var newState = {
      ...this.state,
      values: { ...this.state.values, [name]: value },
      errors: { ...this.state.errors, [name]: false },
      errorMsg: { ...this.state.errorMsg, [name]: "" },
    };
    this.setState(newState);
  };

  render() {
    const { isOpen, handleModal } = this.props;
    const {
      loading,
      values,
      errors,
      errorMsg,
      requestError,
      success,
    } = this.state;
    const modalRoot = isOpen ? "modal-root" : "modal-root hide";
    return (
      <div className={modalRoot}>
        <div
          className="modal-main flex flex-column"
          style={{
            width: "100vw",
            maxWidth: 456,
            minHeight: Math.min(
              typeof window !== "undefined" ? window.innerHeight : 0,
              700
            ),
          }}
        >
          <div className="flex justify-between align-center">
            <h1 className="f3 ma0 fw-bold">Sign up for early access</h1>
            <Icon
              name="multiply"
              className="ma0 pa0 mh2 cp"
              onClick={handleModal}
            />
          </div>
          <p className="ma0 f5 secondary-text-color mt4">
            Payments app made for you.
          </p>
          {success ? (
            <div
              style={{
                flex: "1 1 auto",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <Icon
                name="blue-check-contained.svg"
                className="db center"
                style={{ height: 64 }}
              />
              <p className="f4 ma0 tc mt8">
                We've successfully received your request. We'll get back to you
                shortly.
              </p>
            </div>
          ) : (
            <div className="mt8">
              <InputWrapper
                label="I'm a:"
                validMessage={errors.userType ? errorMsg.userType : null}
                InputComponent={
                  <Fragment>
                    <div className="input-radio">
                      <input
                        type="radio"
                        id={USER_TYPE.SENDER}
                        value={USER_TYPE.SENDER}
                        name={USER_TYPE.SENDER}
                        checked={values.userType === USER_TYPE.SENDER}
                        onChange={(event) =>
                          this.handleChange("userType", event.target.value)
                        }
                      />
                      <label htmlFor={USER_TYPE.SENDER}>
                        User and would like to pay using Loqal.
                      </label>
                    </div>
                    <div className="input-radio">
                      <input
                        type="radio"
                        id={USER_TYPE.MERCHANT}
                        value={USER_TYPE.MERCHANT}
                        name={USER_TYPE.MERCHANT}
                        checked={values.userType === USER_TYPE.MERCHANT}
                        onChange={(event) =>
                          this.handleChange("userType", event.target.value)
                        }
                      />
                      <label htmlFor={USER_TYPE.MERCHANT}>
                        Merchant looking to get paid by Loqal.
                      </label>
                    </div>
                  </Fragment>
                }
              />
              {values.userType === USER_TYPE.MERCHANT ? (
                <InputWrapper
                  label="Business Name"
                  type="text"
                  rootClassName="mt8"
                  validMessage={
                    errors.businessName ? errorMsg.businessName : null
                  }
                  value={values.businessName}
                  inputClass="large"
                  onChange={(event) =>
                    this.handleChange("businessName", event.target.value)
                  }
                />
              ) : null}
              <InputWrapper
                label="Contact Name"
                type="text"
                validMessage={errors.contactName ? errorMsg.contactName : null}
                value={values.contactName}
                rootClassName="mt8"
                inputClass="large"
                onChange={(event) =>
                  this.handleChange("contactName", event.target.value)
                }
              />
              <InputWrapper
                label="Contact Number"
                type="text"
                rootClassName="mt8"
                inputClass="large"
                validMessage={
                  errors.contactNumber ? errorMsg.contactNumber : null
                }
                value={values.contactNumber}
                onChange={(event) =>
                  this.handleChange("contactNumber", event.target.value)
                }
              />
              <InputWrapper
                label="Email"
                // placeholder="Please enter your email"
                type="text"
                rootClassName="mt8"
                inputClass="large"
                validMessage={errors.email ? errorMsg.email : null}
                value={values.email}
                onChange={(event) =>
                  this.handleChange("email", event.target.value)
                }
              />
              <InputWrapper
                label="ZIP Code"
                type="number"
                rootClassName="mt8"
                inputClass="large"
                validMessage={errors.zip ? errorMsg.zip : null}
                value={values.zip}
                onChange={(event) =>
                  this.handleChange("zip", event.target.value)
                }
              />
              <InputWrapper
                label="Feedback (Optional)"
                rootClassName="mt8"
                InputComponent={
                  <textarea
                    className="input"
                    rows="3"
                    style={{ height: "unset" }}
                    value={values.message}
                    onChange={(event) =>
                      this.handleChange("message", event.target.value)
                    }
                  />
                }
              />
              {requestError ? (
                <p className="mt2 f5 danger-red">
                  An error occured while sending request for demo. Please try
                  again later.
                </p>
              ) : null}
              <div className="mt4 tr">
                <button
                  className="btn-base btn-contained pl6 pr6 f6 tc"
                  style={{
                    height: 40,
                    ...(loading && {
                      opacity: "0.5",
                      transition: "opacity .2s",
                    }),
                  }}
                  onClick={this.handleSave}
                >
                  {loading ? "Loading..." : "Secure Your Spot"}
                </button>
              </div>
              <p className="f5 secondary-text-color mt4 mb8">
                Note: We do not share your personal data with any third party.
              </p>
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default RequestDemo;
