import React from "react";
import styleVars from "../../styles/style-vars";
import Icon from "../Icon";

const Footer = () => {
  const linkClassName =
    "ma0 pa0 f5 mt2 mb2 tertiary-text-color primary-text-color-hover db";
  const linkHeaderClassName = "ma0 pa0 f8 mb6 fw-bold";
  return (
    <div>
      <div
        className={`${styleVars.page.xl} pt10 pb10 pt20-ns pb20-ns grid-12 gutter-row-40 gutter-36-ns`}
      >
        <div className="col-12 col-6-l">
          <Icon name="logo" className="ma0 pa0 mh4" />
          <a
            href="https://www.linkedin.com/company/payloqal"
            className="db"
            target="_blank"
          >
            <div className="circular-box mt2">
              <Icon name="linkedin.png" className="ma0 pa0 mh2" />
            </div>
          </a>
        </div>
        <div className="col-6 col-2-l sig-blue center-l">
          <p className={linkHeaderClassName}>LOQAL</p>
          <a className={linkClassName} href="/">
            Home
          </a>
          <a className={linkClassName} href="/how-it-works">
            How It Works
          </a>
          <a className={linkClassName} href="/merchants">
            Merchants
          </a>
        </div>
        {/* <div className="col-6 col-2-l center-l">
          <p className={linkHeaderClassName}>COMPANY</p>
          <a className={linkClassName} href="/about">
            About Us
          </a>
          <a
            className={linkClassName}
            href="https://angel.co/company/spotlight-co/jobs"
            target="_blank"
          >
            Careers
          </a>
        </div> */}
        <div className="col-6 col-2-l center-l">
          <p className={linkHeaderClassName}>CONTACT</p>
          <a
            className={linkClassName}
            href="mailto:takecontrol@spotlightandcompany.com"
          >
            Email us
          </a>
          <a className={linkClassName} href="tel:+19082493363">
            US: <span className="ib">(+1) 908 249 3363</span>
          </a>
        </div>
        <div className="col-6 col-2-l center-l">
          <p className={linkHeaderClassName}>LEGAL</p>
          <a
            className={linkClassName}
            href="/terms-and-conditions/us/privacy-policy"
          >
            Privacy policy
          </a>
          <a className={linkClassName} href="/terms-and-conditions/us/tou">
            Terms of use
          </a>
          <a
            className={linkClassName}
            href="/terms-and-conditions/us/cookie-policy"
          >
            Cookie Policy
          </a>
        </div>
        <div className="col-12 mt4">
          <p className={linkClassName}>
            Copyright © {new Date().getFullYear()}, Spotlight Services and
            Technologies Incorporated
          </p>
        </div>
      </div>
    </div>
  );
};

export default Footer;
