/**
 * - runtime config
 * holds all config values based on runtime environment
 */
console.log("proc", process.env)
const airtableAPIKey = process.env.GATSBY_AIRTABLE_API_KEY;
const airtableBase = process.env.GATSBY_AIRTABLE_BASE;
const airtableApiUrl = process.env.GATSBY_AIRTABLE_API_URL;
console.log({ airtableAPIKey, airtableBase, airtableApiUrl });

export { airtableAPIKey, airtableBase, airtableApiUrl };